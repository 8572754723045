@import "css/global.sass";

#content {
    #nav-tiles {
        padding: 20px;
        height: 270px;

        a {
            text-decoration: none;
            color: $theme-font; }

        .nav-tile {
            width: 100%;
            height: 250px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-bottom: 15px;

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

            .nav-tile-title {
                background-color: white;
                padding: 5px;

                position: relative;
                top: 15px;

                font-size: 1.2rem; }

            cursor: pointer;

            @include responds-to-hover; } }

    #home-youtube-embed-container {
        position: relative;
        padding-bottom: 56.25%; // For 16:9 ratio, this is the "9" to 100%'s "16"
        padding-top: 30px;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; } }

    img {
        max-width: 100%; } }
