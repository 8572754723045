$theme-gold:  #b5b19a;
$theme-bg: #EDEDED;
$theme-font: darken($theme-gold, 30%); // darken($theme-gold, 20%)

$phone-width: 480px;
$phone-height: 480px;

$sidebar-width: 120px;
$sidebar-height-compact: 50px;

@mixin is-compact {
    @media only screen and (max-width: $phone-width), only screen and (max-height: $phone-height) {
        @content; } }

@mixin is-not-compact {
    @media screen and (min-width: $phone-width) and (min-height: $phone-height) {
        @content; } }

@mixin responds-to-hover {
    transition: transform 0.3s ease;
    &:hover {
        transform: scale(0.97); } }

@mixin limited-dynamic-font-size($rel-size, $limit) {
    font-size: $rel-size + 0vh;
    @media screen and (min-width: ($limit / ($rel-size / 100)) + 0px) {
        font-size: $limit + 0px; } }

@mixin limited-dynamic-width($rel-size, $limit) {
    width: $rel-size + 0vw;
    @media screen and (min-width: ($limit / ($rel-size / 100)) + 0px) {
        width: $limit + 0px; } }
